.g-recaptcha { display: inline-block; }

.checked {
	position: absolute;
	right: 0;
	top:-3px;
	box-shadow: 0 2px 3px rgba(0, 0, 0, .3);
}

.checked-one {
	right: 0;
}

.progress {
	background-color: rgba(0, 0, 0, 0.1);
	height: 20px;

    i { text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7); }
}

.shadow-bar {
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
}

.tooltip.in {
	opacity: 1;
}

.slider-selection {
	background: #81A1C3 !important;
}

.unchecked {
	color: #FF0000 !important;
}

.table-orange {
	background-color: rgba(253,126,20, 0.5);
}

/* .badge-pill {
	width: 40%;
	height: 35%;
	padding: 0;
	padding-top: 4px;
	margin-top: -3px;
} */

.animated-checkbox.checkbox-lg input[type="checkbox"] + .label-text:before {
	font-size: 40px;
}

.pulse-anime {
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	transform: scale(1);
	animation: pulse 2s linear infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.disabled, .disabled div {
	opacity: 0.7;
	cursor: not-allowed;
}

.assistent {
	border-radius: 50%;
	background-color: #598A3E;
	border: 6px solid #FFF;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);

    @media (max-width: 767.98px) {
        width: 90px;
        height: 90px;
    }
}

.btn-widget {
	text-decoration: none !important;
	transition: 0.2s ease;

    &:hover { box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3); }
}

.datetimepicker {
	z-index: 99999999;
}