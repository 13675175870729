a { color: #20C997; }
a:hover { color: #2D2D2D; }

.material-half-bg .cover { background-color: #20C997; }

.valid-feedback { color: #20C997; }

.was-validated .form-control:valid, .form-control.is-valid,
.was-validated .custom-select:valid, .custom-select.is-valid {
	border-color: #20C997;
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus,
.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
	border-color: #20C997;
}

.was-validated .form-check-input:valid ~ .form-check-label, 
.form-check-input.is-valid ~ .form-check-label {
	color: #20C997;
}

.was-validated .custom-control-input:valid ~ .custom-control-label,
.custom-control-input.is-valid ~ .custom-control-label {
	color: #20C997;
}

.was-validated .custom-file-input:valid ~ .custom-file-label,
.custom-file-input.is-valid ~ .custom-file-label {
	border-color: #20C997;
}

.dropdown-item.active, .dropdown-item:active { background-color: #20C997; }

.custom-control-input:checked ~ .custom-control-label::before { background-color: #20C997; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #20C997;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
	background-color: #20C997;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
	background-color: #20C997;
}

.custom-file-input:focus ~ .custom-file-label { border-color: #20C997; }

.custom-file-input:focus ~ .custom-file-label::after { border-color: #20C997; }

.custom-range::-webkit-slider-thumb { background-color: #20C997; }

.custom-range::-moz-range-thumb { background-color: #20C997; }

.custom-range::-ms-thumb { background-color: #20C997; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	background-color: #20C997;
}

.page-link { color: #20C997; }

.page-item.active .page-link {
	background-color: #20C997;
	border-color: #20C997;
}

.progress-bar { background-color: #20C997; }

.datepicker table tr td.active:focus,
.datepicker table tr td.active.highlighted:focus,
.datepicker table tr td.active.focus,
.datepicker table tr td.active.highlighted.focus {
	background-color: #20C997;
	border-color: rgba(0, 0, 0, 0.7);
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active.highlighted:hover {
	background-color: #20C997;
	border-color: rgba(0, 0, 0, 0.7);
}

.datepicker table tr td.active:active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.open > .dropdown-toggle.datepicker table tr td.active,
.open > .dropdown-toggle.datepicker table tr td.active.highlighted {
	background-color: #20C997;
	border-color: rgba(0, 0, 0, 0.7);
}

.datepicker table tr td span.active:focus,
.datepicker table tr td span.active:hover:focus,
.datepicker table tr td span.active.disabled:focus,
.datepicker table tr td span.active.disabled:hover:focus,
.datepicker table tr td span.active.focus,
.datepicker table tr td span.active:hover.focus,
.datepicker table tr td span.active.disabled.focus,
.datepicker table tr td span.active.disabled:hover.focus {
	background-color: #20C997;
	border-color: rgba(0, 0, 0, 0.7);
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover {
	background-color: #20C997;
	border-color: rgba(0, 0, 0, 0.7);
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.open > .dropdown-toggle.datepicker table tr td span.active,
.open > .dropdown-toggle.datepicker table tr td span.active:hover,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled,
.open > .dropdown-toggle.datepicker table tr td span.active.disabled:hover {
	background-color: #20C997;
	border-color: rgba(0, 0, 0, 0.7);
}

.select2-dropdown { border: 2px solid #20C997; }

.select2-container--default .select2-selection--multiple .select2-selection__choice {
	background-color: #20C997;
}

.select2-container--default.select2-container--focus .select2-selection--multiple {
	border-color: #20C997;
}

.select2-container--default.select2-container--open.select2-container--above .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--above .select2-selection--multiple {
	border-color: #20C997;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--single,
.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
	border-color: #20C997;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
	border: 1px solid #20C997;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
	background-color: #20C997;
}

.select2-container--classic .select2-selection--single { border: 2px solid #20C997; }

.select2-container--classic .select2-selection--single:focus { border: 1px solid #20C997; }

.select2-container--classic.select2-container--open .select2-selection--single {
	border: 1px solid #20C997;
}

.select2-container--classic .select2-selection--multiple:focus { border: 1px solid #20C997; }

.select2-container--classic.select2-container--open .select2-selection--multiple {
	border: 1px solid #20C997;
}

.select2-container--classic.select2-container--open .select2-dropdown {
	border-color: #20C997;
}

.fc-state-down, .fc-state-active { background-color: #20C997; }

.fc-event {
	border: 1px solid #20C997;
	background-color: #20C997;
}

.app-header { background-color: #20C997; }
.app-header .app-header__logo { background-color: #20C997; }

.app-header .app-notification .dropdown-menu ul,
.app-header .app-notification .dropdown-menu li,
.app-header .app-notification .dropdown-menu li a {
	background-color: #FFF;
}

.app-menu__item.active, .app-menu__item:hover, .app-menu__item:focus {
  background: #D3D3D3;/*#0D1214*/
  border-left-color: #20C997;
  color: #20C997;/*#fff*/
}

.treeview.is-expanded [data-toggle='treeview'] { border-left-color: #20C997; }

.sidebar-mini.sidenav-toggled .treeview:hover .app-menu__item {
	background: #D3D3D3;
	border-left-color: #20C997;
	color: #20C997;
}

.animated-checkbox input[type="checkbox"]:checked + .label-text:before { color: #20C997; }

.animated-radio-button input[type="radio"]:checked + .label-text:before { color: #20C997; }

.toggle input[type="checkbox"]:checked + .button-indecator:before { color: #20C997; }

.toggle-flip input[type="checkbox"] + .flip-indecator:after { background-color: #20C997; }

.material-half-bg .cover { background-color: #20C997; }

.path { stroke: #20C997; }

.messanger .messages .message.me .info { background-color: #20C997; }

.messanger .messages .message.me .info:after {
	border-color: transparent #20C997 transparent transparent;
}

.messanger .sender input[type="text"] { border: 1px solid #20C997; }

.user .user-tabs .nav-link.active { border-left-color: #414e4a; }