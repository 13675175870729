// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Bootstrap
// @import '~bootstrap/scss/bootstrap';

// Variables
@import 'default';
@import 'search';
@import 'field-list-custom';
@import 'ucode-custom';
@import 'bootstrap-select-custom';
@import 'footer';
@import 'themes/teal';
@import 'login';
@import 'swal';
@import 'player-detail';
@import 'preview';
@import 'widget';
@import 'progress-circle';

@import 'utils/btn-circle';